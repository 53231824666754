.hamburger {
    display: block;
    width: $hamburger-width;

    @include breakpoint(not-desktop) {
        margin-top: 10px;
    }

    svg {
        width: 100%;

        path {
            stroke-dasharray: 27px;
            transition: stroke-dashoffset 0.5s $ease-out 0s;
        }
    }

    .body:not(.is-menu-open) & {


        &:hover {
            svg {
                path {
                    &:nth-of-type(1) {
                        stroke-dashoffset: 10px;
                    }

                    &:nth-of-type(2) {
                        stroke-dashoffset: 20px;
                    }

                    &:nth-of-type(3) {
                        stroke-dashoffset: 10px;
                    }
                }
            }
        }
    }


    body.is-menu-open & {

        svg {
            path {
                transition-delay: 0s;

                &:nth-of-type(1) {
                    stroke-dashoffset: 27px;
                }

                &:nth-of-type(2) {
                    stroke-dashoffset: 27px;
                }

                &:nth-of-type(3) {
                    stroke-dashoffset: 27px;
                }
            }
        }
    }
}
