.decoration {
    position: absolute;
    left: 0;
    top: 1%;
    width: 10px;
    height: 100%;
    transform-origin: top;
    background: $color-orange;

    @include breakpoint(tablet) {
        top: 2%;
        height: 97%;
    }

    &--long {
        height: 140%;
        bottom: 18%;
        top: unset;

        @include breakpoint(desktop) {
            body.is-mac & {
                bottom: 5%;
            }
        }

        @include breakpoint(tablet) {
            @supports (-webkit-touch-callout: none) {
                bottom: 5%;
            }
        }
    }

    .text--medium & {
        @include breakpoint(phone) {
            display: none;
        }
    }
}
