.range {
    height: 100%;

    input {
        -webkit-appearance: none;
        width: 100%;
        height: 100%;
        background: transparent;
        outline: none;
        cursor: pointer;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $color-orange;
            background-image: url('../../assets/svg/move.svg');
            background-repeat: no-repeat;
            background-size: 11px;
            background-position: 48% 53%;
            transition: background-color 0.6s $ease-out;
            cursor: grab;

            @include breakpoint(not-desktop) {
                box-shadow: none;
                width: 37px;
                height: 37px;
            }
        }
    }
}
