.deposition {
    height: 250px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 1px rgba($color-white, 0);
        background-color: rgba($color-white, 0);
    }

    &::-webkit-scrollbar {
        width: 1px;
        background-color: rgba($color-white, 0);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background-color: rgba($color: $color-pale, $alpha: 0.4);
    }


    &__item {
        @include flex(row, flex-start, center);
        margin-bottom: 1px;
        border-top: 1px solid rgba($color: $color-pale, $alpha: 0.2);
        padding: 5px;
        width: 100%;
        color: $color-text;

        &:hover {
            background-color: rgba($color: $color-pale, $alpha: 0.1);
        }

        .square {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 10px;
        }

        .volume {
            margin-left: 30px;
        }
    }
}
