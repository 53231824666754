$menu-decoration-size: 78px;

.menu {
    @include z-index(menu);
    position: absolute;
    inset: 0 0 auto;
    background-color: $color-menu-bg;
    width: 100%;
    overflow: hidden;

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;

        img {
            position: relative;
            top: -120px;
            width: 100%;
        }

        @include breakpoint(not-desktop) {
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -10%);
                max-width: unset;
                height: unset;
                width: auto;
            }
        }
    }

    &__inner {
        @include flex(row, flex-end, flex-start);
        width: 100%;
        position: relative;
        padding: $margin - 8px $hamburger-width + $margin * 1 + 12px $margin 0;
        z-index: 1;

        @include breakpoint(not-desktop) {
            padding: 80px 20px 30px 20px;
        }
    }

    &__footnote {
        @include font-size(11, 12);
        position: absolute;
        inset: auto auto 12px $margin;
        color: $color-menu-footnote;

        @include breakpoint(not-desktop) {
            inset: none;
            bottom: 12px;
            left: 18px;
        }
    }

    &__logo {
        position: absolute;
        pointer-events: auto;
        padding: 34px;

        svg {
            width: 214px;
        }

        @include breakpoint(phone) {
            padding: 18px;

            svg {
                width: 171px;
                height: 48px;
            }
        }
    }

    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        padding: 34px 34px 0 0;

        @include breakpoint(phone) {
            padding: 18px;
        }
    }

    nav {
        display: block;
    }

    ul {
        @include flex(column, flex-start, flex-end);
        gap: 6px;
        list-style: none;
        text-align: right;
    }

    li {
        @include font-size(32, 33);
        letter-spacing: -0.5px;
        color: $color-menu-text;
        font-weight: 900;

        a {
            display: block;
            position: relative;

            span {
                transition: transform 0.6s $ease-custom;
                display: block;
            }

            * {
                pointer-events: none;
            }

            &::after {
                content: "";
                position: absolute;
                inset: auto 0 2px auto;
                height: 12px;
                transform: scaleX(0);
                width: $menu-decoration-size;
                background-color: $color-orange;
                transition: all 0.6s $ease-custom;
                transition-delay: 0s;
                transform-origin: right;
                pointer-events: none;
            }

            &.is-active,
            &:hover {
                // transform: translateX(-8px);

                span {
                    display: block;
                    transform: translateX(-$menu-decoration-size - 8px);
                }

                &::after {
                    transition-delay: 0.05s;
                    transform: scaleX(1);
                    margin-left: 8px;
                }
            }
        }

        sup {
            @include font-size(10, 11);
            color: $color-menu-footnote;
            display: inline-block;
            letter-spacing: normal;
            font-weight: 400;
            margin-right: 5px;
            position: relative;
            top: -4px;
        }
    }
}
