
.description {
    @include flex(row, center, center);
    font-family: "TEG", sans-serif;
    max-width: 334px;
    padding-left: 22px;
    
    &__decorator {
        width: 10px;
        height: 100%;
        background-color: $color-orange;
        margin-right: 12px;
        position: absolute;
        inset: 0 auto 0 0;
    }

    h3 {
        @include font-size(30, 1);
        font-weight: 900;

        & + p {
            margin-top: 12px;
        }
    }

    p {
        @include font-size(16, 20);

        &:first-child {
            margin-top: -4px;
        }

        & + h3 {
            margin-top: 6px;
        }
    }
}