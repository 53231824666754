$size-hd: 1600px;
$size-desktop: 1025px;
$size-tablet: 660px;
$size-min: 320px;

$size-laptop-height: 640px;

$margin: 34px;
$mobile-margin: 28px;

$menu: 370px;
$header-height: 84px;

$hamburger-width: 27px;
