
.svg-list {

    path {
        stroke-dasharray: 20;
        stroke-dashoffset: 20;
    }

    @for $i from 1 through 5 {

        path:nth-of-type(#{6 - $i}) {
            animation: svg-list 4s infinite ease;
            animation-delay: #{$i * 0.2}s;
        }
    }

}


.svg-lines {
    transform-origin: center;
}
