$stroke-viewfinder: 617;
$stroke-list: 711;

.button {
    @include flex(row, flex-start, center);
    position: relative;
    padding: 10px 16px 10px 16px;
    width: auto;
    height: 41px;
    margin: 10px;
    font-family: $font-sans;
    


    &--viewfinder {

        
        @include hover {

            .button__border--main path {
                stroke-dashoffset: $stroke-viewfinder;
                transition-delay: 0s;
            }

            .button__border--extra path {
                stroke-dashoffset: 0;
                transition-delay: 0.25s;
            }

        }
    }


    &--list {

        
        @include hover {

            .button__border--main path {
                stroke-dashoffset: $stroke-list;
                transition-delay: 0s;
            }

            .button__border--extra path {
                stroke-dashoffset: 0;
                transition-delay: 0.25s;
            }

        }
    }


    @include breakpoint(not-desktop) {
        padding: 0 10px 0 12px;
        border: 1px solid $color-orange;
        border-radius: 21px;

        @supports not (-webkit-touch-callout: none) {
            padding-top: 5px;
        }
    }


    @include breakpoint(phone-small) {
        padding: 3px 10px 5px 12px;
    }




    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scaleY(0);
        transform-origin: bottom;
        background-color: $color-orange;
        transition: transform 0.5s $ease-out;
        z-index: -1;
    }

    span:not(.button__border) {
        @include font-size(25, 1);
        padding-bottom: 3px;
        margin: 0 10px;
        color: $color-orange;
        font-weight: 800;
        transition: color 0.3s $ease-out;

        @include breakpoint(not-desktop) {
            padding-top: 0;
        }

        @include breakpoint(phone-small) {
            @include font-size(22);
        }
    }


    &__border {
        position: absolute;
        inset: 0;
        margin-bottom: 1px;
        width: 101%;
        height: 102%;

        svg {
            width: 100%;
            height: 100%;
        }

        path {
            transition: stroke-dashoffset 1.5s $ease-custom;
        }

        &--extra {

            path {
                transition-delay: 0s;
            }

            .button--viewfinder & {
                
                path {
                    stroke-dashoffset: -$stroke-viewfinder;
                    stroke-dasharray: $stroke-viewfinder;
                }
            }

            .button--list & {
                
                path {
                    stroke-dashoffset: -$stroke-list;
                    stroke-dasharray: $stroke-list;
                }
            }
        }

        &--main {

            path {
                transition-delay: 0.25s;
            }
            
            .button--viewfinder & {

                path {
                    stroke-dashoffset: 0;
                    stroke-dasharray: $stroke-viewfinder;
                }
            }

            .button--list & {

                path {
                    stroke-dashoffset: 0;
                    stroke-dasharray: $stroke-list;
                }
            }
        }

        @include breakpoint(not-desktop) {
            display: none;
        }
    }
}
