.legend {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: 17px;
    gap: 6px 25px;
    position: absolute;
    inset: auto auto 90px 34px;

    @include breakpoint(not-desktop) {
        width: 100%;
        grid-template-columns: repeat(3, calc(30% - 2px));
        gap: 6px auto;
        inset: unset;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }

    @include breakpoint(tablet) {
        width: 70%;
    }

    &__item {
        @include flex(row, flex-start, center);

        .panel__value {
            @include font-size(11, 14);
            margin-left: 0;
            margin-right: auto;

            @include breakpoint(not-desktop) {
                margin: 0;
            }
        }

        svg {
            width: 6px;
        }
    }
}
