.home {
    &__wrapper {
        @include flex(column, center, center);
        width: 100%;
        height: 100%;

        @include breakpoint(not-desktop) {
            @include flex(column, flex-start, flex-start);
        }
    }

    &__text-wrapper {
        width: 655px;
    }

    &__button-wrapper {
        @include flex(row, center, center);
        width: auto;
    }

    &__logo {
        position: absolute;
        bottom: 35px;
        left: 35px;
    }
}
