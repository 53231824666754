.section {
    @include flex(column, flex-start, center);
    width: 100%;
    // max-height: 100vh;
    position: relative;
    z-index: 1;

    body.is-map-extended & {
        pointer-events: none;
    }

    &__heading {
        @include breakpoint(phone) {
            position: absolute;
            left: 0;
            top: $header-height;
            min-width: 300px;
        }
    }

    &.is-full {
        height: 100vh;

        @include breakpoint(not-desktop) {
            height: var(--app-height);
            padding-top: calc(#{$header-height} + 5px);
        }
    }

    &.is-center {
        @include breakpoint(desktop) {
            justify-content: center;
            align-items: center;
        }
    }

    &.is-bottom {
        justify-content: flex-end;
        align-items: center;
    }

    &__flex {
        @include flex(row, center, center);
        width: auto;

        @include breakpoint(phone) {
            @include flex(column, center, center);
            width: 100%;
        }
    }
    &__wrapper {
        height: 100%;

        @include flex(column, center, flex-start);

        @include breakpoint(phone) {
            @include flex(column, space-between, flex-start);
            padding-bottom: 37px;
        }
    }

    &__legend {
        @include breakpoint(not-desktop) {
            width: calc(100% - 36px);
            position: absolute;
            bottom: 150px;
            left: 50%;
            transform: translateX(-50%);
            padding-left: 4px;
        }

        @include breakpoint(tablet) {
            padding-left: vw(80px);
        }
    }

    &__inner {
        @include breakpoint(desktop) {
            margin-bottom: 10px;
            margin-left: 12px;
        }

        @include breakpoint(phone) {
            align-self: flex-start;
        }

        @include breakpoint(tablet) {
            margin-top: -100px;
        }
    }

    &__logo {
        position: absolute;
        bottom: 35px;
        left: 35px;

        @include breakpoint(not-desktop) {
            bottom: 20px;
            left: 50%;
            margin-left: -61px;
        }

        @include breakpoint(phone) {
            width: 74px;
            height: 18px;
            margin-left: -37px;
        }
    }

    &__panel {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 34px;
    }
}
