
.scroller {
    @include flex();
    position: absolute;
    inset: 0 8px 0 auto;
    width: 48px;
    height: 100%;
    padding: 16px 0 21px + 16px;

    &__inner {
        @include flex(row, center, flex-start);
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__toggle {
        width: 1px;
        height: 49px;
        background-color: $color-scroller-toggle;
        position: relative;
        z-index: 1;
    }

    &__stroke {
        width: 1px;
        height: 100%;
        background-color: $color-scroller-stroke;
        position: absolute;
        inset: 0;
        margin: auto;
    }
}