:root {
    --app-height: 100%;
}

main {
    @include z-index(content);
    min-height: var(--app-height);
    position: relative;
    overflow: hidden;
    scrollbar-width: none;

    body.is-map-extended & {
        pointer-events: none;
        overflow-y: hidden;
    }
}

::-webkit-scrollbar {
    width: 0;
}

html,
body {
    min-height: 100%;
    height: 100%;
    color: $color-text;
    background-color: $color-bg;

    &.is-full-height {
        overflow: hidden;

        main {
            overflow-y: hidden;
        }
    }
}

body {
    @include font-family(sans);
    color: $color-text;
}

#content {
    height: 100%;
}

.content {
    position: relative;
    @include z-index(content);
}

// debug dat gui
.dg.ac {
    z-index: 100 !important;
    display: none;
}
