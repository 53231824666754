.text {
    position: relative;
    max-width: 570px;
    font-family: $font-sans;

    &.is-decoration {
        padding-left: 20px;
    }

    @include breakpoint(phone) {
        max-width: 290px;

        &.is-decoration {
            padding-left: 25px;
        }
    }

    h2 {
        @include font-size(32, 33);
        margin-top: 4px;
        margin-bottom: 10px;
        font-weight: 800;
        letter-spacing: -0.45px;
        pointer-events: all;
        width: fit-content;
        position: relative;

        @include breakpoint(not-desktop) {
            @include font-size(29, 30);

            span {
                height: 29px;
                vertical-align: top;
            }
        }

        @include breakpoint(phone-small) {
            @include font-size(23, 1);
        }
    }

    &__paragraph {
        @include font-size(18, 22);
        max-width: 511px;
        margin-top: 15px;

        @include breakpoint(phone) {
            @include font-size(16, 22);
            padding-left: 22px;
        }

        &--phone-hide {
            @include breakpoint(phone) {
                display: none;
            }
        }
    }

    &__icon {
        position: relative;
        top: -5px;
        left: 3px;
        display: inline-block;
        pointer-events: none;

        @include breakpoint(not-phone) {
            display: none;
        }
    }

    &__info {
        @include breakpoint(not-phone) {
            display: none;
        }

        @include font-size(13, 17);
        background: $color-popup-bg;
        position: relative;
        top: 17px;
        padding: 12px;
        border-radius: 8px;
        width: 300px;
        height: fit-content;
        box-shadow: 0 4px 13px rgba(0, 0, 0, 0.15);
        pointer-events: none;
        transition: opacity 0.15s linear;
        opacity: 0 !important;

        &::before {
            content: "";
            position: absolute;
            top: 3px;
            left: 50%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 20px;
            border-color: transparent transparent $color-popup-bg transparent;
            margin: auto auto 0 auto;
            transform: translateY(-100%) translateX(-50%);
        }

        &.is-visible {
            opacity: 1 !important;
            pointer-events: auto;
        }
    }

    p + p {
        margin-top: 30px;
    }

    &__label {
        @include font-size(16, 16);
        max-width: 100%;

        @include breakpoint(not-desktop) {
            @include font-size(12, 16);
        }
    }

    &__decoration {
        position: absolute;
        left: 0;
        top: 1%;
        width: 10px;
        height: 98%;
        background: $color-orange;

        body.is-mac & {
            top: 4%;
            height: 96%;
        }

        &--long {
            height: 140% !important;
            bottom: 16%;
            top: unset;

            body.is-mac & {
                top: unset;
                bottom: 2px;
            }
        }
    }

    &--medium {
        @include breakpoint(desktop) {
            .text__decoration {
                body.is-mac & {
                    top: 1%;
                    height: 98%;
                }

                &--long {
                    body.is-mac & {
                        top: unset;
                        bottom: 2px;
                    }
                }
            }
        }

        @include breakpoint(phone) {
            padding-left: 0 !important;

            .text__decoration {
                display: none;
            }

            .text__label {
                @include font-size(12, 16);
            }

            h2 {
                @include font-size(25, 25);
                vertical-align: top;
            }
        }
    }

    &--small {
        padding-left: 0;

        .text__label {
            @include font-size(12, 16);

            @include breakpoint(desktop) {
                font-size: vw(13px);
                line-height: vw(18px);
            }

            @include breakpoint(not-desktop) {
                @include font-size(10, 14);
            }
        }

        h2 {
            @include font-size(22, 18);
            margin-top: 0;

            @include breakpoint(desktop) {
                font-size: vw(24px);
                line-height: vw(22px);
            }

            @include breakpoint(not-desktop) {
                @include font-size(19);
            }
        }
    }
}
