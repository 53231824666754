.corners {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    @include z-index(corners);
    pointer-events: none;

    @include breakpoint(not-desktop) {
        display: none;
    }

    &__inner {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__corner {
        width: 33px;
        position: absolute;
        transform-origin: center;

        svg {
            width: 100%;
        }

        // top left
        &:nth-of-type(1) {
            inset: 0 auto auto 0;
            transform: rotate(90deg);
        }

        // top right
        &:nth-of-type(2) {
            inset: 0 0 auto auto;
            transform: rotate(180deg);
        }

        // bottom right
        &:nth-of-type(3) {
            inset: auto 0 0 auto;
            transform: rotate(270deg);
        }

        // bottom left
        &:nth-of-type(4) {
            inset: auto auto 0 0;
        }
    }
}
