.loader {
    @include flex(column, flex-end, flex-start);
    @include z-index(loader);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-bg;
    padding: 33px;

    @include breakpoint(phone) {
        padding: 18px;
    }

    &__logotype {
        width: 517px;
        height: auto;

        @include breakpoint(phone) {
            width: 171px;
            height: 50px;
        }
    }

    &__counter {
        @include font-size(32, 0.7);
        vertical-align: bottom;
        font-weight: 800;
        color: $color-orange;

        @include breakpoint(phone) {
            @include font-size(25);
        }
    }

    &__video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;
        opacity: 0;
        animation: fadeVideo 0.4s linear 0.2s;
        animation-fill-mode: forwards;

        @include breakpoint(phone) {
            animation: fadeVideo 1s linear 1s;
        }

        video {
            filter: sepia(20%);
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.5;
            object-fit: cover;

            @include breakpoint(not-desktop) {
                height: 100vh;
                object-position: 29% 50%;
            }
        }

        @include breakpoint(not-desktop) {
            width: 150%;
        }
    }
}
