.header {
    @include z-index(header);
    position: absolute;
    inset: 0 0 auto 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: $margin;
    pointer-events: none;

    body:not(.is-loaded) & {
        * {
            pointer-events: none !important;
        }

        .toggle {
            visibility: hidden;
        }
    }

    @include breakpoint(not-desktop) {
        padding: 18px;
    }

    &__toggle {
        position: relative;
    }

    &__logo {
        pointer-events: auto;
        width: 214px;
        transform-origin: top left;
        transition: transform 0.8s $ease-custom;
        transform: scale(2.42);

        svg {
            overflow: visible;

            .atom {
                transition: transform 0.8s $ease-custom;
                transform-origin: 88.7% 51.5%;
            }
        }

        @include breakpoint(phone) {
            width: 171px;
            height: 48px;
            transform: scale(1.2);
        }

        body.is-loaded & {
            transform: scale(1);
        }

        @include hover {
            svg .atom {
                transform: rotate(180deg);
            }
        }
    }


    &__zone {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: -20px 0 0 0;
        pointer-events: none;

        @include breakpoint(phone) {
            top: 40px;
        }
    }
}
