.bar {
    @include flex(row, space-between, center);
    @include font-size(16, 1);
    position: relative;
    width: fit-content;
    min-width: 560px;
    height: 100%;
    padding-right: 17px;
    padding-left: 18px;
    border-radius: 56px;
    color: $color-panel-text;
    font-family: "TEG", sans-serif;

    @include breakpoint(not-desktop) {
        @include flex(column, center, flex-start);
        min-width: unset;
        height: 50px;
        width: 100%;
        border-radius: 15px;
    }

    @include breakpoint(tablet) {
        max-width: 85%;
        margin: 0 auto;
        padding: 3px 15px;
    }

    &__button {
        @include font-size(16);
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__toggle {
        margin-left: 20px;
        width: 26px;

        @include breakpoint(not-desktop) {
            position: absolute;
            right: 13px;
            top: 35%;
        }
    }

    &__dropdown {
        width: 100%;
        height: fit-content;
        position: absolute;
        inset: auto 0 21px auto;
        z-index: -1;
        clip-path: inset(0 0 0 0);
        pointer-events: none;
    }

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        background-color: $color-orange;
        z-index: -1;
        pointer-events: none;
        transition: background-color 0.6s $ease-out;
        border-radius: 56px;

        @include breakpoint(not-desktop) {
            border-radius: 14px;
        }
    }
}
