.date {
    @include flex(row, flex-start, center);
    gap: 10px;
    width: fit-content;
    height: 100%;

    @include breakpoint(desktop) {
        min-width: 275px;
    } 

    @include breakpoint(phone) {
        justify-content: flex-start;
        width: 100%;
        height: 12px;
        @include font-size(12, 14);
    }

    &__hour {
        font-weight: 700;
        min-width: 75px;
    }
}
