.about {
    &__wrapper {
        @include flex(column, flex-start, center);
        padding-top: calc(#{$header-height} + 30px);
        overflow-y: auto;
        position: relative;
        width: 100%;
        height: 100vh;

        @include breakpoint(phone) {
            padding: 100px 18px 50px;
            align-items: flex-start;

            + .heading {
                display: none;
            }
        }

        @include breakpoint(tablet) {
            padding-top: calc(#{$header-height} + 60px);
        }
    }

    &__bg {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
        opacity: 0;
        animation: fadeVideo 1s linear 0.3s;
        animation-fill-mode: forwards;

        video {
            filter: sepia(20%);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include breakpoint(not-desktop) {
                height: 100vh;
                object-position: 29% 50%;
            }
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-bg;
            opacity: 0.7;
        }
    }

    &__content {
        padding-bottom: 100px;
    }

    &__description {
        @include breakpoint(desktop) {
            margin: 30px 0 0 20px;
        }
    }

    &__image {
        position: relative;
        width: 223px;
        height: 170px;
        margin-bottom: 30px;
        border-radius: 10px;
        overflow: hidden;
        margin-left: 20px;
        box-shadow: 0 4px 13px rgba(0, 0, 0, 0.14);

        img {
            position: absolute;
            object-fit: cover;
            width: auto;
            height: 125%;
            object-position: 100%;
        }
    }
}
