.searchbar {
    @include flex(row, space-between, center);
    padding: 0 15px 0 20px;
    width: 533px;
    height: 41px;
    background: $color-orange;
    border-radius: 58px;
    color: $color-gray-medium;

    svg {

        path,
        circle {
            stroke: $color-gray-medium;
        }

        path {
            stroke-dasharray: 8;
            // stroke-dashoffset: 8;
        }

        circle {
            stroke-dasharray: 51;
            // stroke-dashoffset: 50.12;
        }
    }

    @include breakpoint(not-desktop) {
        width: 340px;
        height: 50px;
    }

    input {
        position: relative;
        border-width: 0;
        background: transparent;
        color: $color-gray-medium;
        outline: none;
        top: -1px;
        transition: border-width 0.5s linear;

        &::placeholder {
            color: $color-gray-medium;
        }

        &:not(:placeholder-shown) ~ label span {
            visibility: hidden;
        }
    }

    label {
        @include flex(row, space-between, center);
        position: absolute;
        padding: 0 15px 0 20px;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        cursor: pointer;

        * {
            pointer-events: none;
        }

        span {
            position: relative;
            top: -1px;
        }
    }
}
