.video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    video {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
        object-fit: cover;

        @include breakpoint(not-desktop) {
            height: 100vh;
            object-position: 29% 50%;
        }

        @include breakpoint(phone) {
            top: 41%;
        }
    }

    @include breakpoint(not-desktop) {
        width: 150%;
    }
}
