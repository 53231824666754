.popup {
    @include flex(column, flex-start, flex-start);
    @include font-size(16, 1);
    color: $color-popup-text;
    // background-color: $color-popup-bg;
    transition: background-color 0.35s ease;
    font-weight: 400;
    width: 312px;
    height: auto;
    border-radius: 8px;
    // box-shadow: 0 4px 13px rgba(0, 0, 0, 0.15);
    z-index: 2;
    pointer-events: auto;

    @include hover {
        background-color: darken($color-popup-bg, 4%);
        
        // .popup__inner::before {
        //     border-color: darken($color-popup-bg, 4%) transparent transparent transparent;
        // }
    }



    @include breakpoint(phone) {
        width: 280px;
    }




    &__inner {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 12px;
        background-color: $color-popup-bg;
        box-shadow: 0 4px 13px rgba(0, 0, 0, 0.15);
        border-radius: 8px;

        @include breakpoint(phone) {
            @include font-size(13, 14);
        }

        // &::before {
        //     content: "";
        //     position: absolute;
        //     inset: auto 0 6px 0;
        //     width: 0;
        //     height: 0;
        //     border-style: solid;
        //     border-width: 20px 20px 0 20px;
        //     border-color: $color-popup-bg transparent transparent transparent;
        //     transition: all 0.35s ease;
        //     margin: auto auto 0 auto;
        //     transform: translateY(100%);
        // }

        div {
            & + .popup__value {
                margin-top: 8px;
            }
        }
    }



    &__value {
        @include flex(row, flex-start, center);
        @include font-size(21);
        gap: 4px;

        @include breakpoint(phone) {
            @include font-size(16, 17);
        }

        & + div {
            margin-top: 21px;
        }

        svg {
            position: relative;
            top: 1px;
        }
    }



    strong {
        font-weight: 700;
    }



    &__button {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }


    &__triangle {
        position: absolute;
        inset: auto 0 6px 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 20px 0 20px;
        border-color: $color-popup-bg transparent transparent transparent;
        // transition: all 0.35s ease;
        margin: auto auto 0 auto;
        transform: translateY(100%);
    }
}
