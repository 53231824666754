.heading {
    position: absolute;
    top: 30px;
    right: 12%;
    max-width: 334px;
    @include z-index(content);

    @include breakpoint(desktop) {
        .list__heading-wrapper & {
            position: static;
            grid-row: 1;
            grid-column: 3;
        }
    }

    @include breakpoint(tablet) {
        .list__heading-wrapper & {
            position: static;
        }
    }

    @include breakpoint(phone) {
        position: absolute;
        top: 2px;
        left: 20px;
        width: 100%;
        right: unset;
    }
}
