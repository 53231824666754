.dropdown {
    @include flex(row, space-between, center);
    width: 100%;
    height: 280px;
    background-color: $color-dropdown-bg;
    border-radius: 8px;
    padding-bottom: 21px;
    padding-left: 17px;
    opacity: 0;

    @include breakpoint(phone) {
        padding-top: 15px;
        border-radius: 14px;
    }

    &__inner {
        @include flex(column, flex-start, flex-start);
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        position: relative;
        padding-right: 17px + 26px + 20px;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    &__items {
        width: 100%;
    }

    &__item {
        @include flex(row, space-between, center);
        color: $color-graphite;
        padding: 12px 0;
        width: 100%;
        position: relative;

        &::before,
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: $color-dropdown-item-stroke;
            position: absolute;
            inset: auto 0 0;
        }

        &::after {
            transform: scaleX(0);
            transform-origin: left;
            background-color: $color-graphite;
            z-index: 1;
            transition: transform 0.7s $ease-custom;
        }

        &.is-active,
        &:hover {

            &::after {
                transform: scaleX(1);
            }
        }   

        &:last-of-type {

            &::before,
            &::after {
                display: none;
            }   
        }

        &:first-of-type {
            padding-top: 16px;
        }

        &:last-of-type {
            padding-bottom: 16px;
        }

        button {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
        }

        @include breakpoint(phone) {
            @include flex(column, center, flex-start);
            padding: 6px 0;
        }
    }


    &__item-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        pointer-events: none;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.5s $ease-custom;
    }
}
