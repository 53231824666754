.icon-close {
    display: inline-block;
    position: relative;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    transform: rotate(0);
    transition: transform $time-fast $easing, opacity $time-fast $easing;

    &::before,
    &::after {
        content: '';
        display: block;
        width: 20px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -1px -10px;
        background: currentColor;
    }

    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(135deg);
    }
}

%icon-close-hover {
    transform: rotate(-90deg);
}

a,
button {
    &:hover {
        .icon-close {
            @extend %icon-close-hover !optional;
        }
    }
}
