.arrow {
    width: $hamburger-width;
    transition: transform 0.5s $ease-out;
    display: block;
    pointer-events: none;

    @include breakpoint(not-desktop) {
        margin-top: 10px;
    }

    svg {
        width: 100%;

        path {
            stroke-dasharray: 39px;
            stroke-dashoffset: -39px;
            transition: stroke-dashoffset 0.7s $ease-out 0s;

            @supports (-webkit-touch-callout: none) {
                stroke-dashoffset: 39px;
            }
        }
    }

    body.is-menu-open & {
        pointer-events: auto;

        path {
            transition-delay: 0.2s;
            stroke-dashoffset: 0;
        }
    }
}
