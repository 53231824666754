@keyframes svg-list {
    0% {
        stroke-dasharray: 20;
        stroke-dashoffset: 20;
    }

    30% {
        stroke-dashoffset: 0;
    }

    55% {
        stroke-dasharray: -20;
        stroke-dashoffset: 0;
    }

    90% {
        stroke-dashoffset: -20;
    }

    100% {
        stroke-dashoffset: -20;
    }
}

@keyframes svg-lines {
    0% {
        stroke-dashoffset: 0;
    }

    20% {
        stroke-dashoffset: 50;
    }

    25% {
        opacity: 0;
        stroke-dashoffset: -50;
    }

    26% {
        opacity: 1;
        stroke-dashoffset: -50;
    }

    50% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeVideo {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
