.map {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    position: absolute;
    inset: 0;

    &__wrap {
        height: var(--app-height);
    }

    &__range {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 20px;
        width: calc(100% - #{$menu});

        input {
            width: 100%;
        }
        label {
            display: block;
        }
    }

    &__controls {
        position: absolute;
        top: 0;
        right: 0;
        width: $menu;
        height: 100vh;
        padding: 50px 20px 20px;
        background-color: rgba($color-black, 0.85);

        .wrap {
            border-top: 1px solid $color-pale;
            margin-bottom: 15px;
            padding-top: 15px;

            &:first-of-type {
                padding-top: 0;
                border-top: none;
            }
        }

        .autoplay-button {
            display: block;
            margin: 0 auto;
            height: 50px;
            text-align: center;

            .circle {
                display: block;
                margin: 0 auto;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: $color-red;
            }

            &:hover {
                .circle {
                    opacity: 0.7;
                }
            }
        }
    }

    &__popup {
        position: absolute;
        // bottom: $margin * 3;
        // right: $menu + $margin;
        top: 0;
        left: 0;
        max-width: 312px;
        width: fit-content;
        height: fit-content;
    }

    &__description {
        width: 334px;
        height: 176px;
        position: absolute;
        inset: 32px 92px auto auto;

        @include breakpoint(phone) {
            top: calc(#{$header-height} + 5px);
            left: 0;
        }
    }

    &__marker {
        @include flex();
        width: 40px;
        height: 1px;
        position: relative;

        svg {
            position: absolute;
            inset: 0;
            transform: translateY(-100%);
            width: 40px;
        }
    }

    .mapboxgl-ctrl-bottom-right,
    .mapboxgl-ctrl-bottom-left {
        visibility: hidden;
    }
}
