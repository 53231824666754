.list {
    position: absolute;
    top: 0;
    width: 100%;
    overflow-y: auto !important;
    height: var(--app-height);

    @include breakpoint(not-desktop) {
        position: absolute;
        left: 0;
    }

    &__container {
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: -1;

        @include breakpoint(not-desktop) {
            margin-top: $header-height;
            padding: 0 18px;
            width: 100%;
        }
    }

    &__heading-wrapper {
        position: absolute;
        top: 30px;
        right: 150px;

        @include breakpoint(desktop) {
            width: 100%;
            left: 0;
            right: unset;
            display: grid;
            grid-template-columns: repeat(auto-fill, vw(460px));
            justify-content: center;
            grid-gap: 20px;
        }

        @include breakpoint(tablet) {
            top: -65px;
        }

        @include breakpoint(phone) {
            position: fixed;
            top: 80px;
            left: 0;
            width: 100%;
            right: unset;
            z-index: 2;
        }
    }

    &__wrapper {
        margin: 0 auto;
        margin-top: $header-height;
        padding: 34px;
        padding-bottom: 120px;
        width: 100%;
        position: relative;
        z-index: 0;

        @include breakpoint(desktop) {
            padding: 34px 0 120px;
        }

        .is-grid & {
            display: grid;
            grid-template-columns: repeat(auto-fill, vw(494px));
            justify-content: center;
            grid-gap: 20px;
        }

        @include breakpoint(not-desktop) {
            margin-top: calc(#{$header-height} - 25px);
            grid-template-columns: repeat(auto-fill, 340px) !important;
            grid-gap: 12px !important;
            padding: 0 0 300px 0;
            width: 100%;
        }

        @include breakpoint(tablet) {
            margin-top: calc(#{$header-height} - 70px);
        }
    }

    &__decoration {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10px;
        height: 18%;
        background: $color-orange;
        transition: height 0.7s $ease-out;

        @include breakpoint(desktop) {
            width: vw(10px);
            min-width: 10px;
        }
    }

    &__item {
        position: relative;
        top: 0;
        width: 394px;
        height: 126px;
        background-color: $color-gray-light;
        display: block;
        border-radius: 10px;
        box-shadow: 0 4px 13px rgba(0, 0, 0, 0.15);
        padding: 5px;
        overflow: hidden;
        color: $color-text;
        text-decoration: none;
        display: flex;
        height: 100%;

        &.is-behind-header {
            pointer-events: none;
        }

        .is-list & {
            height: 177px;
        }

        a {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        @include breakpoint(desktop) {
            width: vw(494px);
            height: vw(165px);
            min-height: 126px;
            border-radius: vw(13px);
            box-shadow: 0 vw(5px) vw(14px) rgba(0, 0, 0, 0.15);
            padding: vw(7px);
        }

        @include breakpoint(not-desktop) {
            height: 109px;
            max-width: 340px;
        }

        @include breakpoint(tablet) {
            margin: 0;
        }

        .is-list & {
            margin-bottom: 24px;
        }

        @include hover {

            .list__decoration {
                height: 100%;
            }

            .list__image svg {
                transform: scale(1);
            }
        }
    }

    &__searchbar {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        z-index: 1;

        @include breakpoint(not-desktop) {
            bottom: 37px;
        }
    }

    &__content {
        position: relative;
        padding: 5px 0;
        padding-left: 7px;
    }

    &__date {
        @include font-size(16, 20);
        font-family: $font-sans;
        position: absolute;
        bottom: 0;
        left: 7px;

        @include breakpoint(desktop) {
            font-size: vw(16px);
            line-height: vw(20px);
        }

        @include breakpoint(not-desktop) {
            @include font-size(14, 15);
        }
    }

    &__image {
        @include flex();
        position: relative;
        left: -5px;
        top: -5px;
        overflow: hidden;
        height: calc(100% + 10px);
        width: 127px;

        @include breakpoint(desktop) {
            height: calc(100% + 17px);
            top: vw(-7px);
            left: vw(-8px);
            width: vw(165px);
            min-width: 127px;
        }

        .is-list & {
            width: 229px;
        }

        img {
            width: 300px;
            height: 300px;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }
        
        svg {
            width: 40%;
            max-width: 100%;
            max-height: 100%;
            position: relative;
            display: block;
            margin: auto;
            z-index: 1;
            transform-origin: center;
            transition: transform 0.6s $ease-out; 
            transform: scale(0);

            body.touchevents & {
                transform: scale(1);
            }
        }
    }

    &__footer {
        position: fixed;
        bottom: 33px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;

        .grid-button {
            margin-right: 20px;
        }
    }
}
