.panel {
    @include flex(column, flex-end, center);
    width: 100%;
    padding-left: 34px;
    padding-right: 34px;
    position: relative;
    z-index: 4;

    @include breakpoint(phone) {
        padding: 0 18px;
    }

    &__timeline {
        @include flex(row, flex-start, center);
        @include z-index(timeline, $panel-elements);
        width: 100%;
        height: 41px;
        border-radius: 56px;
        border: 1px solid $color-orange;
        position: relative;
    }

    &__title {
        @include font-size(16);
        @include z-index(title, $panel-elements);
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        z-index: 1;

        strong {
            font-weight: 900;
        }

        @include breakpoint(phone) {
            @include font-size(15, 15);
            margin-bottom: 0;
            white-space: nowrap;
        }
    }

    &__autoplay {
        position: relative;
        width: 41px;
        height: 41px;
        min-width: 41px;
        min-height: 41px;
        margin-left: 44px;
        border-radius: 50%;
        background-color: $color-orange;
        transition: background-color 0.3s $ease-out;
        margin-right: 0;
        justify-self: flex-end;

        @include breakpoint(not-desktop) {
            margin-left: 10px;
            width: 37px;
            height: 37px;
            min-width: unset;
            min-height: unset;
            margin-bottom: 15px;
        }

        svg {
            position: absolute;
            display: block;
        }

        .svg-play {
            inset: 0 0 0 14px;
            display: block;
            width: 19px;
            margin: auto 0;

            @include breakpoint(not-desktop) {
                inset: 0 0 0 13px;
                width: 16px;
            }
        }

        .svg-pause {
            inset: 0 0 0 1px;
            width: 14px;
            margin: auto;

            path {
                stroke: $color-panel-text;
            }

            @include breakpoint(not-desktop) {
                width: 10px;
            }
        }
    }

    &__info {
        width: 100%;
        pointer-events: auto;
        z-index: 2;
    }

    &__range {
        flex: 1;

        input {
            width: 100%;
        }

        @include breakpoint(not-desktop) {
            border: 1px solid $color-orange;
            border-radius: 19px;
            height: 37px;
            margin-bottom: 15px;
        }
    }

    &__value {
        @include flex(row, center, center);
        // margin-left: 32px;
        @include font-size(16, 17);
        letter-spacing: -0.3px;
        margin-left: auto;
        margin-right: 0;

        svg {
            width: 21px;
            margin-right: 7px;

            @include breakpoint(not-desktop) {
                margin-right: 4px;
                margin-bottom: 3px;
            }
        }

        strong {
            margin-right: 3px;
        }

        @include breakpoint(not-desktop) {
            @include font-size(12, 13);
            margin-left: 0;
            margin-top: 4px;
        }

        @include breakpoint(tablet) {
            margin-top: -1px;
            margin-bottom: 2px;
        }

        @include breakpoint(desktop) {
            .dropdown__item &,
            .bar & {
                justify-content: space-between;
                min-width: 152px;
            }
        }
    }

    &__inner {
        width: 100%;
        @include flex(row, space-between, center);
        pointer-events: auto;
        position: relative;
        z-index: 2;

        @include breakpoint(tablet) {
            max-width: 85%;
        }
    }

    &__zone {
        position: absolute;
        inset: auto 0 0 0;
        width: 100%;
        height: 140px;
        background-color: transparent;
        pointer-events: auto;
        bottom: -40px;

        @include breakpoint(phone) {
            height: 180px;
        }
    }
}
